<template>

    <div>

        <div class="breadcrumb justify-content-end">
            <div class="d-flex  breadcrumb-wrapper pr-1">
                <b-breadcrumb-item :to="{ name: 'dashboard' }">
                    <feather-icon class="align-text-top" icon="HomeIcon" size="15"/>
                </b-breadcrumb-item>
                <b-breadcrumb-item active>{{$t('label_agents_invoices')}}</b-breadcrumb-item>
            </div>
        </div>

        <b-card
                no-body
                class="mb-0"
        >
            <provision-table :module="module" :agentId="agent_id" :filter-data="filterData"></provision-table>

        </b-card>

    </div>
</template>

<script>

    import {
        BCard, BCardBody, BButton
    } from 'bootstrap-vue'

    import vSelect from 'vue-select'

    import infinityScroll from '@/views/components/infinityScroll'
    import provisionTable from './../../pages/agent/includes/provisionTable'

    import {A_INVOICE_PREFIX as PREFIX} from './moduleHelper'

    export default {

        components: {
            BCard,
            BCardBody,
            BButton,

            vSelect,
            infinityScroll,
            provisionTable

        },

        data() {
            return {
                PREFIX,

                editedItem: false,

                filterData: {
                    status_id: null,
                },

                dealStatuses: [],
                module: 'module_admin',
                agent_id: 0
            }
        },

        created() {
            // let auth_user = this.authUser();
            // if(auth_user.role == 'admin') {
            //     this.module = 'module_admin';
            // } else if(auth_user.role == 'agent') {
            //     this.agent_id = auth_user.id;
            // }
            this.getPageData();
        },

        methods: {

            getPageData() {
                this.async('get', '/select_options', {params:{options:['deal_statuses']}}, function(res){
                    // this.taskTypes = res.data.agrm_task_types;
                    this.dealStatuses = res.data.deal_statuses
                });
            },

        },

    }
</script>

